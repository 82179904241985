import { ENVIRONMENT_CONFIG } from "./environment.config";

export const environment = {
    stage: 'staging',
    apiUrl: ENVIRONMENT_CONFIG.staging.apiUrl,
    cognitoUserPool: ENVIRONMENT_CONFIG.staging.cognitoUserPool,
    cognitoClientId: ENVIRONMENT_CONFIG.staging.cognitoClientId,
    externalElabelUrl: ENVIRONMENT_CONFIG.staging.externalElabelUrl,

    cognitoDomain: ENVIRONMENT_CONFIG.staging.cognitoDomain,
    redirectUrl: ENVIRONMENT_CONFIG.staging.redirectUrl,
};
